/********** Template CSS **********/
:root {
    /* --primary: #32C36C; */
    --primary: #0d5f56;
    --light: #F6F7F8;
    --dark: #ed2121;
}

.fw-medium {
    font-weight: 500 !important;
}
.text-white{
    color: white !important;
}
.fw-bold {
    font-weight: 700 !important;
}

.fw-black {
    font-weight: 900 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
    background-color: var(--dark) !important;
    color: white !important;
}
 .back-to-top:hover{
    border-radius: 1px solid blue;
}

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}
.btn:hover{
    background-color: #ed2121 !important;
    border-color:  #ed2121 !important;
    color: #FFFFFF !important ;
}
.btn.btn-primary,
.btn.btn-outline-primary:hover {
    color: #FFFFFF;
}
.btn.btn-outline-primary:hover{
    background-color: #ed2121 !important;
  }

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}
.header-container {
    position: relative !important;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-position: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 70px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}
/* lets make earth cleaner */
.cleaner_section{

    /* padding-top: 0px !important; */
    /* padding-bottom: 0px !important; */
    
    padding-bottom: 0px !important;
    background-color: #f8f9fa !important;
    
    padding-top: 4.375rem;
    padding-bottom: 4.25rem !important;
}
  .button-container {
    text-align: center; /* Center the button horizontally */
    margin-top: 1.5rem; /* Adjust the margin as needed */
}
  .cleaner-h2 {
    text-align: center;
    color: #9B9B9B;
    /* font-size: 45px; */
    /* font-weight: normal; */
    margin: 0 auto; /* Center horizontally */
    position: relative;
    /* top: 50%; */
    /* left: 50%; */
    /* -ms-transform: translate(-50%, -50%); */
    /* transform: translate(-50%, -50%); */
}
.title-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    height: 1px;
    border-bottom: 3px solid #0d5f56;
    /* width: fit-content; */
    transition: border-width 0.3s ease; /* Adjust the transition duration and timing function as needed */
  
}
.cleaner-p{
    align-items: center;
    /* padding-bottom: 1.75rem; */
    text-align: center;
    /* font-size: 1rem; */
    color: #9B9B9B;
    /* display: inline-block; */
    display: block;
    margin-bottom: 1em; /* Adjust as needed for desired spacing */
}
.cleaner-btn{
    /* background-color: white !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flow !important;
    color: black !important;
    width:50%;
    float: inline-start; */
    cursor: pointer;
    display: inline-block;
    background-color: #007bff; /* Adjust button background color as needed */
    color: #fff; /* Adjust button text color as needed */
    padding: 0.75rem 1.5rem; /* Adjust button padding as needed */
    border-radius: 2rem; /* Adjust button border radius as needed */
    transition: background-color 0.3s ease; /* Add smooth transition on hover */
}
.cleaner-btn:hover {
    background-color: #0056b3; /* Adjust button background color on hover as needed */
}
  
  /* end of lets make earth cleaner */
  
  /* why droit section */
  
.why-droit-icon {
    width: 70%;
    height: auto;
}
  
  /* end why droit section */
  
  /* what we do section */
.whatwedo-icon {
    position: relative;
    /* margin: -50px 0 25px 0; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    /* background: #FFFFFF; */
    /* border-radius: 100px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08); */
    transition: .5s;
}
  
.service-item:hover .whatwedo-icon {
    color: #FFFFFF;
    background: var(--primary);
}
.whatwedo-sec .card {
    border: none;
    transition: all 0.3s;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
.whatwedo-sec .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
  
.whatwedo-sec .card-title {
    margin-top: 1rem;
}
  
  
.feature-box--large-icon {
    padding: 3.375rem 3.125rem 4.875rem; 
     text-align: center;
}
  /* end what we do */


  /* mission section */
.mission-section{
    background-color: #FFFFFF !important;
}
  /* end mission section */

/* blog section */


.blog-posts-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .post-link {
    margin: 20px 20px 20px 20px;
  }
  
  .post-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 400px;
    background-color: #fff;
    box-shadow: 0px 2px 20px rgba(0,0,0,.25);
    transition: all .25s;
    top: 0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .post-wrap:hover {
    top: 2px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.25);
  }
  
  .post-image {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .post-body {
    width: 300px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .post-body-primary {
    margin: 40px 0 40px 0;
  }
  
  .post-meta {
    color: #9B9B9B;
    
  }
  
  .post-meta b {
    font-weight: 500;
  }
  
  .post-title {
    color: #444;
    font-size: 20px;
    padding: 5px 0 10px 0;
  }
  
  .post-text {
    color: #7B7B7B;
  }
  
  .post-body-secondary {
    border-top: 1px solid #ECECEC;
  }
  
  .post-category {
    color: #7B7B7B;
    padding: 15px 0 10px 0;
  }
  
  /* Button Styling */
  .button-wrap {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
  }
  
  .button {
    position: relative;
    background-color: #3089DB;
    padding: 20px 60px 20px 60px;
    box-shadow: 0px 6px 20px rgba(48,137,219,.60);
    transition: all .25s;
    top: 0;  
    border-radius: 10px;
    
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .button:hover {
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(48,137,219,.60);
    top: 2px;
  }


/* end blog section */

/* Partner section */
.sponsor-slider {
    max-width: 100%;
  }
  
  .sponsor-slider h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sponsor-slider .slick-slider {
    margin: 0 auto;
  }
  
  .sponsor-slider .slick-slide img {
    width: 30%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
  }
  
  /* Optional: Customize slider dots */
  .sponsor-slider .slick-dots li button:before {
    color: var(--dark);
    font-size: 12px;
  }
  
  /* Optional: Style active dot */
  .sponsor-slider .slick-dots li.slick-active button:before {
    color: #0d5f56; /* Change to your preferred active dot color */
  }
  
/* end of partner section */


/* partner1 section */
#ourclients {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    height: 150px;
  }
  #ourclients .clients-wrap {
    display: block;
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
  }
  #ourclients .clients-wrap ul {
    display: flex; /* Change to flex */
    list-style: none;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    transition: margin-left 2s ease-in-out; /* Add transition */
  }
  #ourclients .clients-wrap ul li {
    flex: 0 0 220px; /* Set fixed width */
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  #ourclients .clients-wrap ul li img {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
  
  
/* end partner1 section */
@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .dropdown-menu {
      display: none;
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      z-index: 1000;
      min-width: 10rem;
      padding: 0.5rem 0;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: .25rem;
      animation: fadeIn 0.5s;
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
          transform: translateY(-10px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  }
  
  .dropdown-menu.show {
      display: block;
  }
  
  .dropdown-menu a {
      display: block;
      padding: 0.5rem 1rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      transition: background-color 0.3s;
  }
  
  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
      text-decoration: none;
      background-color: #f8f9fa;
  }
  .dropdown-menu li {
    border-bottom: 1px solid #dee2e6; /* Add border between items */
  }
  
  .dropdown-menu li:last-child {
    border-bottom: none; /* Remove border for last item */
  }
  
  .dropdown-menu li a {
    padding: 0.75rem 1rem; /* Add padding to the items */
    color: #212529;
    display: block;
    transition: background-color 0.3s;
  }
  
  .dropdown-menu li a:hover,
  .dropdown-menu li a:focus {
    background-color: #f8f9fa; /* Change background color on hover/focus */
  }


    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.owl-carousel-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, .1);
}

@media (max-width: 768px) {
    .header-carousel .owl-carousel-item {
        position: relative;
        min-height: 500px;
    }

    .header-carousel .owl-carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .header-carousel .owl-carousel-item p {
        font-size: 16px !important;
    }
}

.header-carousel .owl-dots {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-carousel .owl-dots .owl-dot {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 5px 0;
    background: #FFFFFF;
    box-shadow: 0 0 30px rgba(255, 255, 255, .9);
    border-radius: 45px;
    transition: .5s;
}

.header-carousel .owl-dots .owl-dot.active {
    width: 60px;
    height: 60px;
}

.header-carousel .owl-dots .owl-dot img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 2px;
    border-radius: 45px;
    transition: .5s;
}

.page-header {
    /* background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url(../img/carousel-1.jpg) center center no-repeat; */
    background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}
.all_section_bg{
    background-color: #f8f9fa !important;  
}
  .all_section_wobg{
    background-color: #fff !important;  
}
  .title-footerline{
    border-bottom: 3px solid #fff;
    width: fit-content;
}

/*** About ***/
.title{
    border-bottom: 3px solid #0d5f56;
    width: fit-content;
    transition: border-width 0.3s ease; /* Adjust the transition duration and timing function as needed */
  
}
@media (min-width: 992px) {
    .container.about {
        max-width: 100% !important;
    }

    .about-text  {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .about-text  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .about-text  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}


/*** Service ***/
.service-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.service-icon {
    position: relative;
    margin: -50px 0 25px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: #FFFFFF;
    border-radius: 100px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .5s;
}

.service-item:hover .service-icon {
    color: #FFFFFF;
    background: var(--primary);
}


/*** Feature ***/
@media (min-width: 992px) {
    .container.feature {
        max-width: 100% !important;
    }

    .feature-text  {
        padding-left: calc(((100% - 960px) / 2) + .75rem) !important;
    }
}

@media (min-width: 1200px) {
    .feature-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem) !important;
    }
}

@media (min-width: 1400px) {
    .feature-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem) !important;
    }
}


/*** Project Portfolio ***/
#portfolio-flters li {
    display: inline-block;
    font-weight: 500;
    color: var(--dark);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
    color: var(--primary);
    border-color: var(--primary);
}

.portfolio-img {
    position: relative;
}

.portfolio-img::before,
.portfolio-img::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    background: var(--dark);
    transition: .5s;
}

.portfolio-img::before {
    left: 50%;
}

.portfolio-img::after {
    right: 50%;
}

.portfolio-item:hover .portfolio-img::before {
    width: 51%;
    left: 0;
}

.portfolio-item:hover .portfolio-img::after {
    width: 51%;
    right: 0;
}

.portfolio-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 1;
    transition: .5s;
}

.portfolio-item:hover .portfolio-btn {
    opacity: 1;
    transition-delay: .3s;
}


/*** Quote ***/

/* captcha */
.captcha-container {
  display: flex;
  align-items: center;
}

.captcha-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc; /* Border for the container */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Hide overflow to prevent visual glitches */
}

.captcha-input {
  flex: 1;
}

.reload-icon-button {
  background: #f0f0f0; /* Background color for the reload button */
  border-left: 1px solid #ccc; /* Vertical border to separate captcha and reload button */
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
}

.reload-icon-button:hover {
  background: #e0e0e0; /* Hover background color for the reload button */
}

.reload-icon-button:focus {
  outline: none; /* Remove focus outline */
}

.captcha-info {
  margin-left: 10px; /* Add margin between captcha container and info */
}

.text-danger {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}




@media (min-width: 992px) {
    .container.quote {
        max-width: 100% !important;
    }

    .quote-text  {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .quote-text  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .quote-text  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}


/*** Team ***/
.team-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.team-item img {
    border-radius: 8px 60px 0 0;
}

.team-item .team-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: #FFFFFF;
    transition: .5s;
}


/*** Testimonial ***/
.testimonial .testimonial-carousel::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial .testimonial-carousel::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 350px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: .5s;
    z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
    width: 300px;
    opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    color: var(--primary);
    font-size: 45px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--dark);
}

.testimonial-carousel .testimonial-img img {
    width: 100px;
    height: 100px;
}

.testimonial-carousel .testimonial-img .btn-square {
    position: absolute;
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial-carousel .owl-item .testimonial-text {
    margin-bottom: 30px;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transform: scale(.8);
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    transform: scale(1);
}

/* loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* end loader */
/*** Contact ***/
@media (min-width: 992px) {
    .container.contact {
        max-width: 100% !important;
    }

    .contact-text  {
        padding-left: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .contact-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .contact-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem);
    }
}


/*** Footer ***/
.footer-section{
    background-color: #0d5f56;
}
.info-container {
    /* display: flex; */
    flex-wrap: wrap;
  }
  
  .info-container p {
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .info-container p i {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
  
.footer .btn.btn-social {
    margin-right: 5px;
    color: #9B9B9B;
    border: 1px solid #9B9B9B;
    border-radius: 38px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
    border-color: var(--light);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #9B9B9B;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: #FFFFFF;
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}
.footer .btn.btn-social {
    margin-right: 5px;
    color: #9B9B9B;
    border: 1px solid #9B9B9B;
    border-radius: 38px;
    transition: .3s;
}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {}
  
section {
      padding: 10px 0;
      min-height: 50vh;
}
  
a,
a:hover,
a:focus,
a:active {
      text-decoration: none;
      outline: none;
}
  
a,
a:active,
a:focus {
      color: #6f6f6f;
      text-decoration: none;
      transition-timing-function: ease-in-out;
      -ms-transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      transition-duration: .2s;
      -ms-transition-duration: .2s;
      -moz-transition-duration: .2s;
      -webkit-transition-duration: .2s;
      -o-transition-duration: .2s;
}
  
ul {
      margin: 0;
      padding: 0;
      list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.footer-section {
  background-color: rgb(10 87 79);
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.footer-section:before {
  content: '';
  position: absolute;
  top: -146%;
  left: -18%;
  width: 44%;
  height: 257%;
  transform: rotate(54deg);
  background-color: rgb(13, 95, 86);
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  z-index: -10;
}
.footer-section:after {
  position: absolute;
  content: '';
  background-color: rgb(13, 95, 86);
  top: -24%;
  right: 4%;
  width: 26%;
  height: 264%;
  transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  z-index: -10;
}
.footer-top {
  padding-top: 30px;
  padding-bottom: 50px;
}
.footer-top p,
.company-footer-contact-list li {
  color: #ffffff;
}
.company-footer-contact-list {
  margin-top: 10px;
}
.company-footer-contact-list li {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.company-footer-contact-list li+li {
  margin-top: 5px;
}
.company-footer-contact-list li i {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
}
  
.footer-top .site-logo {
  margin-bottom: 25px;
  display: block;
  max-width: 50%;
}
.widget-title {
  text-transform: capitalize;
}
.footer-top .widget-title {
  color: #ffffff;
  margin-bottom: 40px;
}
.courses-link-list li+li {
  margin-top: 10px;
}
.courses-link-list li a {
  color: #ffffff;
  text-transform: capitalize;
  font-family: var(--para-font);
  font-weight: 400;
}
.courses-link-list li a:hover {
  color: #ffb606;
}
.courses-link-list li i {
  margin-right: 5px;
}
.footer-top .small-post-title a {
  font-family: var(--para-font);
  color: #ffffff;
  font-weight: 400;
}
.small-post-item .post-date {
  color: #ffb606;
  margin-bottom: 3px;
  font-family: var(--para-font);
  font-weight: 400;
}
.small-post-list li+li {
  margin-top: 30px;
}
.news-letter-form {
  margin-top: 15px;
}
.news-letter-form input {
  width: 100%;
  padding: 12px 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
}
.news-letter-form input[type="submit"] {
  width: auto;
  border: none;
  background-color: #ffb606;
  padding: 9px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #ffffff;
  margin-top: 10px;
}
.footer-bottom {
  padding: 13px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.149);
}
.copy-right-text {
  color: #ffffff;
}
.copy-right-text a {
  /* color: #ed2121; */
  font-weight: bold;
  
  color: white;
}
.terms-privacy li+li {
  margin-left: 30px;
}
.terms-privacy li a {
  color: #ffffff;
  position: relative;
}
.terms-privacy li a:after {
  position: absolute;
  content: '-';
  color: #ffffff;
  display: inline-block;
  top: 0;
  right: -18px;
}
.terms-privacy li+li a:after {
  display: none;
}